import './src/styles/main.css';
import 'typeface-montserrat';
import 'typeface-open-sans';

import firebase from 'firebase/app';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCGxc7_QTQBEGQJ5z4_w-a4hWWrTDqmWgs",
  authDomain: "thesurspace.firebaseapp.com",
  projectId: "thesurspace"
});
